import * as React from "react";
import { Link } from "gatsby";

import Logotype from "@components/SVG/logotype";

var d = new Date();
var n = d.getFullYear();

const navigation = {
  menu: [
    { name: "L'Agence", to: "/#agence" },
    { name: "Expertise", to: "/#expertise" },
    { name: "Réalisations", to: "/realisations" },
    { name: "Contact", to: "/contact" },
    { name: "Espace Client", to: "#" },
  ],
  notre_expertise: [
    { name: "Identité Visuelle", to: "/#videos" },
    { name: "Vidéo", to: "/#videos" },
    { name: "Web", to: "/#videos" },
    { name: "Conseil Stratégique", to: "/#videos" },
  ],
  company: [
    { name: "About", to: "#" },
    { name: "Blog", to: "#" },
    { name: "Jobs", to: "#" },
    { name: "Press", to: "#" },
    { name: "Partners", to: "#" },
  ],
  legal: [
    { name: "©Framecut" + " " + n, to: "/" },
    { name: "Mentions légales", to: "/mentions-legales" },
    // { name: "Politique de confidentialité", to: "#" },
  ],
  social: [
    {
      name: "LinkedIn",
      to: "https://www.linkedin.com/company/framecut/",
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.959 11.719v7.379h-4.278v-6.885c0-1.73-.619-2.91-2.167-2.91-1.182 0-1.886.796-2.195 1.565-.113.275-.142.658-.142 1.043v7.187h-4.28s.058-11.66 0-12.869h4.28v1.824l-.028.042h.028v-.042c.568-.875 1.583-2.126 3.856-2.126 2.815 0 4.926 1.84 4.926 5.792zM2.421.026C.958.026 0 .986 0 2.249c0 1.235.93 2.224 2.365 2.224h.028c1.493 0 2.42-.989 2.42-2.224C4.787.986 3.887.026 2.422.026zM.254 19.098h4.278V6.229H.254v12.869z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "YouTube",
      to: "https://www.youtube.com/@framecut1942",
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      to: "https://www.instagram.com/framecut_agence/",
      icon: (props: React.SVGProps<SVGSVGElement>) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   name: "Email",
    //   to: "#",
    //   icon: (props: React.SVGProps<SVGSVGElement>) => (
    //     <svg
    //       fill="currentColor"
    //       viewBox="0 0 36 36"
    //       preserveAspectRatio="xMidYMid meet"
    //       {...props}
    //     >
    //       <path
    //         fillRule="evenodd"
    //         d="M32,6H4A2,2,0,0,0,2,8V28a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V8A2,2,0,0,0,32,6ZM30.46,28H5.66l7-7.24-1.44-1.39L4,26.84V9.52L16.43,21.89a2,2,0,0,0,2.82,0L32,9.21v17.5l-7.36-7.36-1.41,1.41ZM5.31,8H30.38L17.84,20.47Z"
    //         clipRule="evenodd"
    //       ></path>
    //       <rect x="0" y="0" width="36" height="36" fillOpacity="0" />
    //     </svg>
    //   ),
    // },
    // {
    // 	name: "Facebook",
    // 	to: "#",
    // 	icon: (props: React.SVGProps<SVGSVGElement>) => (
    // 		<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    // 			<path
    // 				fillRule="evenodd"
    // 				d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
    // 				clipRule="evenodd"
    // 			/>
    // 		</svg>
    // 	),
    // },
    // {
    // 	name: "Twitter",
    // 	to: "#",
    // 	icon: (props: React.SVGProps<SVGSVGElement>) => (
    // 		<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    // 			<path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
    // 		</svg>
    // 	),
    // },
    // {
    // 	name: "GitHub",
    // 	to: "#",
    // 	icon: (props: React.SVGProps<SVGSVGElement>) => (
    // 		<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    // 			<path
    // 				fillRule="evenodd"
    // 				d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
    // 				clipRule="evenodd"
    // 			/>
    // 		</svg>
    // 	),
    // },
  ],
};

export default function Footer({ isHomepage }: { isHomepage?: Boolean }) {
  return (
    <footer
      className={`${isHomepage && "shrink-0 snap-start"} bg-gradient-to-t from-black to-black/80 text-white`}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-4 lg:gap-20">
          <div className="space-y-8">
            <Logotype black={false} />
            <p className="text-sm leading-6 ">
              10 Rue Cambon <br /> 75001 Paris
            </p>
          </div>
          <div>
            <h3 className="text-sm font-semibold capitalize leading-6 text-white">
              menu
            </h3>
            <ul className="mt-6 space-y-4">
              {navigation.menu.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.to}
                    className="text-sm leading-6  hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-10 md:mt-0">
            <h3 className="text-sm font-semibold capitalize leading-6 text-white">
              notre expertise
            </h3>
            <ul className="mt-6 space-y-4">
              {navigation.notre_expertise.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.to}
                    className="text-sm leading-6  hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-10 md:mt-0">
            <h3 className="text-sm font-semibold leading-6 text-white">
              Retrouvez nous sur :
            </h3>
            <ul className="mt-6 space-y-4">
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.to}
                    target="_blank"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon
                      className="size-8 fill-white"
                      aria-hidden="true"
                    />
                  </a>
                ))}
              </div>
            </ul>
          </div>
          {/* <div className="mt-10 md:mt-0">
						<h3 className="text-sm font-semibold leading-6 text-white">
							Company
						</h3>
						<ul role="list" className="mt-6 space-y-4">
							{navigation.company.map(item => (
								<li key={item.name}>
									<Link
										to={item.to}
										className="text-sm leading-6  hover:text-white"
									>
										{item.name}
									</Link>
								</li>
							))}
						</ul>
					</div> */}
        </div>
      </div>
      <div className="mx-auto flex max-w-3xl flex-col justify-center gap-x-7 gap-y-5 px-6 pb-8 pt-16 text-xs lg:flex-row lg:px-8">
        {navigation.legal.map((item) =>
          item.to ? (
            <Link
              key={item.name}
              to={item.to}
              className="text-white hover:text-gray-500"
            >
              {item.name}
            </Link>
          ) : (
            <p key={item.name} className="text-white hover:text-gray-500">
              {item.name}
            </p>
          ),
        )}
      </div>
    </footer>
  );
}
