import * as React from "react";

type LogotypeProps = React.SVGProps<SVGSVGElement> & {
  black?: boolean;
  className?: string;
};

const Logotype = ({ black = false, className }: LogotypeProps) => {
  return (
    <svg
      viewBox="0 0 214 55"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.863 31.11v5.664h10.906v6.458H7.863v10.782H1.151V24.65h17.828v6.46H7.863Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M8.043 54.193H.971V24.471h18.187v6.82H8.043v5.3h10.905v6.818H8.043v10.784Zm-6.713-.36h6.353v-10.78h10.906v-6.1H7.683v-6.022h11.116v-6.1H1.33v29.002Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M31.196 44.367h-3.188v9.647h-6.712V24.65h11.745a10.042 10.042 0 0 1 10.067 10.067 9.673 9.673 0 0 1-5.41 8.515l6.249 10.78h-7.214l-5.537-9.646Zm-3.188-5.872h5.033a3.555 3.555 0 0 0 3.355-3.777 3.554 3.554 0 0 0-3.356-3.774h-5.032v7.55Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M44.258 54.193h-7.63l-5.536-9.647h-2.9v9.646h-7.076v-29.72H33.04a10.26 10.26 0 0 1 10.248 10.245 9.887 9.887 0 0 1-5.34 8.588l6.31 10.888Zm-7.422-.359h6.8l-6.189-10.676.17-.086a9.535 9.535 0 0 0 5.312-8.354 9.9 9.9 0 0 0-9.889-9.888H21.475v28.998h6.353v-9.641H31.3l5.536 9.647ZM33.04 38.67h-5.212v-7.906h5.213a3.723 3.723 0 0 1 3.536 3.954 3.722 3.722 0 0 1-3.537 3.952Zm-4.853-.359h4.853a3.371 3.371 0 0 0 3.176-3.6 3.367 3.367 0 0 0-3.176-3.594h-4.853v7.194Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M62.627 49.4H51.721l-1.469 4.614h-7.34l9.984-29.363h8.558l9.982 29.363h-7.34L62.627 49.4Zm-2.015-6.292-3.438-10.78-3.44 10.781h6.878Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M71.687 54.193h-7.726l-1.468-4.614h-10.64l-1.469 4.614h-7.723l10.107-29.722h8.815l.042.122 10.062 29.6Zm-7.459-.359h6.958l-9.86-28.998h-8.3l-9.865 28.997h6.96l1.469-4.613h11.171l1.467 4.614Zm-3.367-10.547h-7.372l3.685-11.551 3.687 11.551Zm-6.879-.359h6.387l-3.195-10.01-3.192 10.01Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M101.654 54.014h-6.711V36.942L87.351 49.4h-.759l-7.588-12.458v17.072h-6.712V24.65h6.712l7.97 13.046 7.969-13.046h6.712l-.001 29.363Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M101.834 54.193h-7.071V37.581l-7.311 12h-.957l-7.312-12v16.612h-7.071V24.471h6.992l7.87 12.88 7.868-12.88h6.992v29.722Zm-6.712-.359h6.352V24.836h-6.431L86.974 38.04l-8.071-13.21h-6.432v28.998h6.353V36.301l7.873 12.919h.553l7.872-12.92v17.533Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M123.485 47.55v6.46h-18.456V24.651h18.246v6.46h-11.534v4.866h10.488v6.373h-10.488v5.2h11.744Z"
        fill={`${black ? "#000" : "#fff"}`}
      />
      <path
        d="M123.665 54.193h-18.816V24.471h18.606v6.82H111.92v4.506h10.486v6.735H111.92v4.838h11.745v6.823Zm-18.457-.36h18.1v-6.1h-11.747V42.17h10.488v-6.014h-10.488v-5.225h11.534v-6.1h-17.887v29.002Z"
        fill="url(#j)"
      />
      <path
        d="M124.126 39.332a14.876 14.876 0 0 1 4.355-10.914 14.886 14.886 0 0 1 10.915-4.355 14.643 14.643 0 0 1 12.583 6.8l-5.788 3.354a7.588 7.588 0 0 0-6.8-3.607c-5.2 0-8.558 3.482-8.558 8.724 0 5.243 3.361 8.728 8.563 8.728a7.545 7.545 0 0 0 6.8-3.607l5.788 3.356a14.538 14.538 0 0 1-12.583 6.8 14.882 14.882 0 0 1-14.223-9.37 14.893 14.893 0 0 1-1.052-5.909Z"
        fill="url(#k)"
      />
      <path
        d="M139.395 54.782a15.114 15.114 0 0 1-14.368-9.483 15.1 15.1 0 0 1-1.081-5.967 15.114 15.114 0 0 1 9.483-14.366 15.107 15.107 0 0 1 5.966-1.082 14.756 14.756 0 0 1 12.736 6.882l.1.157-6.1 3.537-.09-.156a7.448 7.448 0 0 0-6.646-3.52c-5.09 0-8.378 3.354-8.378 8.545 0 5.192 3.288 8.545 8.378 8.545a7.352 7.352 0 0 0 6.638-3.515l.089-.16 6.1 3.54-.095.157a14.649 14.649 0 0 1-12.732 6.886Zm0-30.537a14.757 14.757 0 0 0-15.09 15.087 14.767 14.767 0 0 0 4.319 10.77 14.757 14.757 0 0 0 10.771 4.32 14.312 14.312 0 0 0 12.333-6.553l-5.472-3.173a7.745 7.745 0 0 1-6.861 3.54c-5.308 0-8.738-3.5-8.738-8.899 0-5.4 3.43-8.904 8.738-8.904a7.837 7.837 0 0 1 6.859 3.542l5.473-3.172a14.414 14.414 0 0 0-12.332-6.56v.002Z"
        fill="url(#l)"
      />
      <path
        d="M153.255 44.534V24.651h6.712v19.3c0 2.223 1.049 4.11 4.615 4.11 3.566 0 4.615-1.887 4.615-4.11v-19.3h6.712v19.883c0 6.29-4.867 10.066-11.326 10.066-6.459 0-11.328-3.774-11.328-10.066Z"
        fill="url(#m)"
      />
      <path
        d="M164.581 54.78c-6.882 0-11.506-4.117-11.506-10.246V24.47h7.071v19.475c0 2.609 1.492 3.93 4.434 3.93s4.435-1.321 4.435-3.93V24.47h7.072v20.063c0 6.129-4.624 10.246-11.506 10.246Zm-11.147-29.95v19.7c0 5.913 4.479 9.887 11.146 9.887 6.667 0 11.147-3.973 11.147-9.887v-19.7h-6.352v19.116c0 2.807-1.658 4.29-4.794 4.29-3.136 0-4.794-1.483-4.794-4.29V24.83h-6.353Z"
        fill="url(#n)"
      />
      <path
        d="M198.781 31.11h-7.55v22.9h-6.712v-22.9h-7.55v-6.459h21.812v6.46Z"
        fill="url(#o)"
      />
      <path
        d="M191.41 54.193h-7.071V31.291h-7.55v-6.82h22.172v6.82h-7.55l-.001 22.902Zm-6.711-.36h6.352V30.931h7.55v-6.1h-21.453v6.099h7.551v22.903Z"
        fill="url(#p)"
      />
      <path
        d="M207.451 10.977a5.399 5.399 0 1 0 0-10.798 5.399 5.399 0 0 0 0 10.798Z"
        fill="url(#q)"
      />
      <path
        d="M207.451 11.157a5.583 5.583 0 0 1-5.154-3.444 5.575 5.575 0 0 1 4.066-7.607 5.577 5.577 0 0 1 5.031 9.415 5.585 5.585 0 0 1-3.943 1.636Zm0-10.8a5.22 5.22 0 1 0 5.22 5.22 5.225 5.225 0 0 0-5.22-5.22Z"
        fill="url(#r)"
      />
      <defs>
        <linearGradient
          id="a"
          x1={10.065}
          y1={24.475}
          x2={10.065}
          y2={53.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#262626" />
          <stop offset={0.024} stopColor="#242424" />
          <stop offset={0.194} stopColor="#1C1C1C" />
          <stop offset={0.516} stopColor="#1A1A1A" />
          <stop offset={0.796} stopColor="#070707" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="b"
          x1={10.065}
          y1={24.828}
          x2={10.065}
          y2={53.837}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset={0.078} stopColor="#262625" />
          <stop offset={0.188} stopColor="#3C3C3B" />
          <stop offset={0.266} stopColor="#3E3E3D" />
          <stop offset={0.313} stopColor="#464645" />
          <stop offset={0.351} stopColor="#545454" />
          <stop offset={0.384} stopColor="#696968" />
          <stop offset={0.415} stopColor="#838382" />
          <stop offset={0.443} stopColor="#A3A3A2" />
          <stop offset={0.47} stopColor="#C8C8C8" />
          <stop offset={0.491} stopColor="#ECECEC" />
          <stop offset={0.528} stopColor="#C6C6C5" />
          <stop offset={0.577} stopColor="#9C9C9B" />
          <stop offset={0.627} stopColor="#797978" />
          <stop offset={0.677} stopColor="#5E5E5D" />
          <stop offset={0.727} stopColor="#4B4B4A" />
          <stop offset={0.779} stopColor="#3F3F3E" />
          <stop offset={0.832} stopColor="#3C3C3B" />
          <stop offset={1} stopColor="#1D1D1B" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={32.621}
          y1={24.475}
          x2={32.621}
          y2={53.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#262626" />
          <stop offset={0.024} stopColor="#242424" />
          <stop offset={0.194} stopColor="#1C1C1C" />
          <stop offset={0.516} stopColor="#1A1A1A" />
          <stop offset={0.796} stopColor="#070707" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="d"
          x1={32.687}
          y1={24.144}
          x2={32.687}
          y2={53.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset={0.078} stopColor="#262625" />
          <stop offset={0.188} stopColor="#3C3C3B" />
          <stop offset={0.266} stopColor="#3E3E3D" />
          <stop offset={0.313} stopColor="#464645" />
          <stop offset={0.351} stopColor="#545454" />
          <stop offset={0.384} stopColor="#696968" />
          <stop offset={0.415} stopColor="#838382" />
          <stop offset={0.443} stopColor="#A3A3A2" />
          <stop offset={0.47} stopColor="#C8C8C8" />
          <stop offset={0.491} stopColor="#ECECEC" />
          <stop offset={0.528} stopColor="#C6C6C5" />
          <stop offset={0.577} stopColor="#9C9C9B" />
          <stop offset={0.627} stopColor="#797978" />
          <stop offset={0.677} stopColor="#5E5E5D" />
          <stop offset={0.727} stopColor="#4B4B4A" />
          <stop offset={0.779} stopColor="#3F3F3E" />
          <stop offset={0.832} stopColor="#3C3C3B" />
          <stop offset={1} stopColor="#1D1D1B" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={57.174}
          y1={24.475}
          x2={57.174}
          y2={53.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#262626" />
          <stop offset={0.024} stopColor="#242424" />
          <stop offset={0.194} stopColor="#1C1C1C" />
          <stop offset={0.516} stopColor="#1A1A1A" />
          <stop offset={0.796} stopColor="#070707" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="f"
          x1={57.174}
          y1={24.144}
          x2={57.174}
          y2={53.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset={0.078} stopColor="#262625" />
          <stop offset={0.188} stopColor="#3C3C3B" />
          <stop offset={0.266} stopColor="#3E3E3D" />
          <stop offset={0.313} stopColor="#464645" />
          <stop offset={0.351} stopColor="#545454" />
          <stop offset={0.384} stopColor="#696968" />
          <stop offset={0.415} stopColor="#838382" />
          <stop offset={0.443} stopColor="#A3A3A2" />
          <stop offset={0.47} stopColor="#C8C8C8" />
          <stop offset={0.491} stopColor="#ECECEC" />
          <stop offset={0.528} stopColor="#C6C6C5" />
          <stop offset={0.577} stopColor="#9C9C9B" />
          <stop offset={0.627} stopColor="#797978" />
          <stop offset={0.677} stopColor="#5E5E5D" />
          <stop offset={0.727} stopColor="#4B4B4A" />
          <stop offset={0.779} stopColor="#3F3F3E" />
          <stop offset={0.832} stopColor="#3C3C3B" />
          <stop offset={1} stopColor="#1D1D1B" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={86.974}
          y1={24.475}
          x2={86.974}
          y2={53.25}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#262626" />
          <stop offset={0.024} stopColor="#242424" />
          <stop offset={0.194} stopColor="#1C1C1C" />
          <stop offset={0.516} stopColor="#1A1A1A" />
          <stop offset={0.796} stopColor="#070707" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="h"
          x1={86.973}
          y1={24.144}
          x2={86.973}
          y2={53.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset={0.078} stopColor="#262625" />
          <stop offset={0.188} stopColor="#3C3C3B" />
          <stop offset={0.266} stopColor="#3E3E3D" />
          <stop offset={0.313} stopColor="#464645" />
          <stop offset={0.351} stopColor="#545454" />
          <stop offset={0.384} stopColor="#696968" />
          <stop offset={0.415} stopColor="#838382" />
          <stop offset={0.443} stopColor="#A3A3A2" />
          <stop offset={0.47} stopColor="#C8C8C8" />
          <stop offset={0.491} stopColor="#ECECEC" />
          <stop offset={0.528} stopColor="#C6C6C5" />
          <stop offset={0.577} stopColor="#9C9C9B" />
          <stop offset={0.627} stopColor="#797978" />
          <stop offset={0.677} stopColor="#5E5E5D" />
          <stop offset={0.727} stopColor="#4B4B4A" />
          <stop offset={0.779} stopColor="#3F3F3E" />
          <stop offset={0.832} stopColor="#3C3C3B" />
          <stop offset={1} stopColor="#1D1D1B" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={114.257}
          y1={24.475}
          x2={114.257}
          y2={53.247}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#262626" />
          <stop offset={0.024} stopColor="#242424" />
          <stop offset={0.194} stopColor="#1C1C1C" />
          <stop offset={0.516} stopColor="#1A1A1A" />
          <stop offset={0.796} stopColor="#070707" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="j"
          x1={114.257}
          y1={24.144}
          x2={114.257}
          y2={53.42}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D1D1B" />
          <stop offset={0.078} stopColor="#262625" />
          <stop offset={0.188} stopColor="#3C3C3B" />
          <stop offset={0.266} stopColor="#3E3E3D" />
          <stop offset={0.313} stopColor="#464645" />
          <stop offset={0.351} stopColor="#545454" />
          <stop offset={0.384} stopColor="#696968" />
          <stop offset={0.415} stopColor="#838382" />
          <stop offset={0.443} stopColor="#A3A3A2" />
          <stop offset={0.47} stopColor="#C8C8C8" />
          <stop offset={0.491} stopColor="#ECECEC" />
          <stop offset={0.528} stopColor="#C6C6C5" />
          <stop offset={0.577} stopColor="#9C9C9B" />
          <stop offset={0.627} stopColor="#797978" />
          <stop offset={0.677} stopColor="#5E5E5D" />
          <stop offset={0.727} stopColor="#4B4B4A" />
          <stop offset={0.779} stopColor="#3F3F3E" />
          <stop offset={0.832} stopColor="#3C3C3B" />
          <stop offset={1} stopColor="#1D1D1B" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={124.121}
          y1={63.324}
          x2={155.715}
          y2={19.246}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#153853" />
          <stop offset={0.084} stopColor="#153853" />
          <stop offset={0.125} stopColor="#183E5D" />
          <stop offset={0.195} stopColor="#225077" />
          <stop offset={0.286} stopColor="#336CA3" />
          <stop offset={0.344} stopColor="#3F81C3" />
          <stop offset={0.528} stopColor="#92D2FF" />
          <stop offset={0.709} stopColor="#3F81C3" />
          <stop offset={0.899} stopColor="#153853" />
          <stop offset={1} stopColor="#153853" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={138.087}
          y1={24.591}
          x2={138.087}
          y2={54.724}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#153853" />
          <stop offset={0.035} stopColor="#1C405D" />
          <stop offset={0.096} stopColor="#305877" />
          <stop offset={0.174} stopColor="#5180A3" />
          <stop offset={0.266} stopColor="#7EB5E0" />
          <stop offset={0.274} stopColor="#83BBE6" />
          <stop offset={0.351} stopColor="#85BCE6" />
          <stop offset={0.397} stopColor="#8DC0E6" />
          <stop offset={0.435} stopColor="#9CC6E7" />
          <stop offset={0.468} stopColor="#B0D0E8" />
          <stop offset={0.498} stopColor="#CADCEA" />
          <stop offset={0.526} stopColor="#E9EAEB" />
          <stop offset={0.528} stopColor="#ECECEC" />
          <stop offset={0.539} stopColor="#E3E7EB" />
          <stop offset={0.587} stopColor="#C0D7E9" />
          <stop offset={0.636} stopColor="#A5CBE7" />
          <stop offset={0.686} stopColor="#92C2E6" />
          <stop offset={0.737} stopColor="#86BCE6" />
          <stop offset={0.79} stopColor="#83BBE6" />
          <stop offset={1} stopColor="#153853" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={153.255}
          y1={63.136}
          x2={185.607}
          y2={25.694}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#153853" />
          <stop offset={0.084} stopColor="#153853" />
          <stop offset={0.125} stopColor="#183E5D" />
          <stop offset={0.195} stopColor="#225077" />
          <stop offset={0.286} stopColor="#336CA3" />
          <stop offset={0.344} stopColor="#3F81C3" />
          <stop offset={0.528} stopColor="#92D2FF" />
          <stop offset={0.709} stopColor="#3F81C3" />
          <stop offset={0.899} stopColor="#153853" />
          <stop offset={1} stopColor="#153853" />
        </linearGradient>
        <linearGradient
          id="n"
          x1={164.581}
          y1={24.592}
          x2={164.581}
          y2={54.72}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#153853" />
          <stop offset={0.035} stopColor="#1C405D" />
          <stop offset={0.096} stopColor="#305877" />
          <stop offset={0.174} stopColor="#5180A3" />
          <stop offset={0.266} stopColor="#7EB5E0" />
          <stop offset={0.274} stopColor="#83BBE6" />
          <stop offset={0.351} stopColor="#85BCE6" />
          <stop offset={0.397} stopColor="#8DC0E6" />
          <stop offset={0.435} stopColor="#9CC6E7" />
          <stop offset={0.468} stopColor="#B0D0E8" />
          <stop offset={0.498} stopColor="#CADCEA" />
          <stop offset={0.526} stopColor="#E9EAEB" />
          <stop offset={0.528} stopColor="#ECECEC" />
          <stop offset={0.539} stopColor="#E3E7EB" />
          <stop offset={0.587} stopColor="#C0D7E9" />
          <stop offset={0.636} stopColor="#A5CBE7" />
          <stop offset={0.686} stopColor="#92C2E6" />
          <stop offset={0.737} stopColor="#86BCE6" />
          <stop offset={0.79} stopColor="#83BBE6" />
          <stop offset={1} stopColor="#153853" />
        </linearGradient>
        <linearGradient
          id="o"
          x1={176.969}
          y1={62.377}
          x2={208.761}
          y2={26.238}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#153853" />
          <stop offset={0.084} stopColor="#153853" />
          <stop offset={0.125} stopColor="#183E5D" />
          <stop offset={0.195} stopColor="#225077" />
          <stop offset={0.286} stopColor="#336CA3" />
          <stop offset={0.344} stopColor="#3F81C3" />
          <stop offset={0.528} stopColor="#92D2FF" />
          <stop offset={0.709} stopColor="#3F81C3" />
          <stop offset={0.899} stopColor="#153853" />
          <stop offset={1} stopColor="#153853" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={187.875}
          y1={24.59}
          x2={187.875}
          y2={54.699}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#153853" />
          <stop offset={0.035} stopColor="#1C405D" />
          <stop offset={0.096} stopColor="#305877" />
          <stop offset={0.174} stopColor="#5180A3" />
          <stop offset={0.266} stopColor="#7EB5E0" />
          <stop offset={0.274} stopColor="#83BBE6" />
          <stop offset={0.351} stopColor="#85BCE6" />
          <stop offset={0.397} stopColor="#8DC0E6" />
          <stop offset={0.435} stopColor="#9CC6E7" />
          <stop offset={0.468} stopColor="#B0D0E8" />
          <stop offset={0.498} stopColor="#CADCEA" />
          <stop offset={0.526} stopColor="#E9EAEB" />
          <stop offset={0.528} stopColor="#ECECEC" />
          <stop offset={0.539} stopColor="#E3E7EB" />
          <stop offset={0.587} stopColor="#C0D7E9" />
          <stop offset={0.636} stopColor="#A5CBE7" />
          <stop offset={0.686} stopColor="#92C2E6" />
          <stop offset={0.737} stopColor="#86BCE6" />
          <stop offset={0.79} stopColor="#83BBE6" />
          <stop offset={1} stopColor="#153853" />
        </linearGradient>
        <linearGradient
          id="q"
          x1={218.832}
          y1={15.566}
          x2={205}
          y2={-8.178}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#153853" />
          <stop offset={0.084} stopColor="#153853" />
          <stop offset={0.125} stopColor="#183E5D" />
          <stop offset={0.195} stopColor="#225077" />
          <stop offset={0.286} stopColor="#336CA3" />
          <stop offset={0.344} stopColor="#3F81C3" />
          <stop offset={0.528} stopColor="#92D2FF" />
          <stop offset={0.709} stopColor="#3F81C3" />
          <stop offset={0.899} stopColor="#153853" />
          <stop offset={1} stopColor="#153853" />
        </linearGradient>
        <linearGradient
          id="r"
          x1={199.54}
          y1={-0.113}
          x2={216.578}
          y2={12.139}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.084} stopColor="#153853" />
          <stop offset={0.123} stopColor="#1C405D" />
          <stop offset={0.189} stopColor="#305877" />
          <stop offset={0.276} stopColor="#5180A3" />
          <stop offset={0.377} stopColor="#7EB5E0" />
          <stop offset={0.386} stopColor="#83BBE6" />
          <stop offset={0.429} stopColor="#85BCE6" />
          <stop offset={0.455} stopColor="#8DC0E6" />
          <stop offset={0.476} stopColor="#9CC6E7" />
          <stop offset={0.495} stopColor="#B0D0E8" />
          <stop offset={0.512} stopColor="#CADCEA" />
          <stop offset={0.527} stopColor="#E9EAEB" />
          <stop offset={0.528} stopColor="#ECECEC" />
          <stop offset={0.534} stopColor="#E3E7EB" />
          <stop offset={0.559} stopColor="#C0D7E9" />
          <stop offset={0.586} stopColor="#A5CBE7" />
          <stop offset={0.612} stopColor="#92C2E6" />
          <stop offset={0.639} stopColor="#86BCE6" />
          <stop offset={0.667} stopColor="#83BBE6" />
          <stop offset={0.899} stopColor="#153853" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Logotype;
