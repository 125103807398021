import React, { useState } from "react";
import { Link } from "gatsby";
import Logotype from "@components/SVG/logotype";

import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const navigation = [
  { name: "L'agence", href: "/#agence" },
  { name: "expertise", href: "/#expertise" },
  { name: "realisations", href: "/realisations" },
  { name: "contact", href: "/contact" },
  {
    name: "espace client",
    href: "https://quickconnect.to/framecut",
    externalLink: true,
  },
];

export default function Navbar({ isHomepage }: { isHomepage?: Boolean }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className={`${isHomepage && "shrink-0 snap-start"} bg-white`}>
      <nav
        className="center mx-auto flex max-w-full items-end justify-between p-6 uppercase lg:grid lg:grid-cols-3 lg:justify-items-center"
        aria-label="Global"
      >
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map(
            (item, i) =>
              i < 3 && (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900 transition-all hover:text-gray-600"
                >
                  {item.name}
                </Link>
              ),
          )}
        </div>
        <div className="flex lg:flex-initial">
          <Link to="/">
            <Logotype black={true} className="h-12 w-auto" />
            <span className="sr-only">Your Company</span>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 "
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item, i) =>
            i > 2 ? (
              item.externalLink ? (
                <a
                  target="_blank"
                  key={item.name}
                  href={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900 transition-all hover:text-gray-600"
                >
                  {item.name}
                </a>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm font-semibold leading-6 text-gray-900 transition-all hover:text-gray-600"
                >
                  {item.name}
                </Link>
              )
            ) : null,
          )}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <Logotype black={true} className="h-8 w-auto" />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold uppercase leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
