import * as React from "react";

import Footer from "@components/Footer/Footer";
import Navbar from "@components/Navbar/Navbar";

export default function Layout({ children, path }: any) {
  const isHomepage = path === "/";
  return (
    <>
      {!isHomepage && <Navbar />}
      <main>{children}</main>
      {!isHomepage && <Footer />}
    </>
  );
}
